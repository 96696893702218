import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~3],
		"/dashboard": [~4,[2]],
		"/dashboard/assessment/batches": [~5,[2]],
		"/dashboard/assessment/batches/create": [~7,[2]],
		"/dashboard/assessment/batches/[id]": [~6,[2]],
		"/dashboard/assessment/blueprints": [~8,[2]],
		"/dashboard/assessment/codes": [~9,[2]],
		"/dashboard/assessment/generation-jobs": [~10,[2]],
		"/dashboard/assessment/generation-jobs/create": [~11,[2]],
		"/dashboard/assessment/prompt-templates": [~12,[2]],
		"/dashboard/assessment/prompt-templates/create": [13,[2]],
		"/dashboard/assessment/results/[code]": [~14,[2]],
		"/dashboard/atreides-old": [~16,[2]],
		"/dashboard/atreides": [~15,[2]],
		"/dashboard/class-material": [17,[2]],
		"/dashboard/class-material/assigner": [18,[2]],
		"/dashboard/notification": [~19,[2]],
		"/dashboard/notification/[id]": [~20,[2]],
		"/dashboard/sephora": [21,[2]],
		"/dashboard/sephora/cart": [22,[2]],
		"/dashboard/sephora/order": [23,[2]],
		"/dashboard/survey": [~24,[2]],
		"/login": [~25]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';